import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Find all widget divs
const widgetDivs = document.querySelectorAll('.calqulator-widget');

// Inject our React App into each class
widgetDivs.forEach(div => {
    ReactDOM.render(
        <App/>,
        div
    );
});

import React, { useContext } from "react";
import CalqulatorHeader from "../calqulator-header/CalqulatorHeader";
import CalqulatorBody from "../calqulator-body/CalqulatorBody";
import CalqulatorFooter from "../calqulator-footer/CalqulatorFooter";
import { StateContext } from "../../context/StateContext.js";

export default function Dashboard() {
  const {
    isCalqulator,
    isBuilder,
    isBuilderCalqulator,
    calculatedResult,
    partnerType,
  } = useContext(StateContext);

  var mainDiv = document.querySelectorAll(".calqulator-widget");

  return (
    <>
      {(isCalqulator ||
        isBuilder ||
        isBuilderCalqulator ||
        (partnerType && isCalqulator)) && (
        <div>
          <CalqulatorHeader mainDiv={mainDiv} />
          {calculatedResult !== null && (
            <>
              <CalqulatorBody mainDiv={mainDiv} />
              <CalqulatorFooter mainDiv={mainDiv} />
            </>
          )}
        </div>
      )}
    </>
  );
}

import React from 'react'
import '../styles/CalqulatorFooter.css'

function CalqulatorFooter() {
  return (
    <div className='main-footer-container'>
      <div className='footer-container'>
        <p className='footer-text'>Powered By</p>
      </div>
      <div className='footer-container'>
        <img className='header-logo' src='https://calqueinc.com/wp-content/uploads/2024/07/Color-H-1.png' alt="calque_logo" />
      </div>
      <div className='footer-container'>
        <p className='footer-text'>Calque is not a lender, but partners with lenders to provide this offering. This Calqulator is for informational purposes only, it is not a formal offer of credit or of a specific Purchase Price Guarantee amount. Actual offers will vary based on lender underwriting criteria and the valuation of your home.</p>
      </div>
    </div>
  )
}

export default CalqulatorFooter
import React, { useState, useEffect, useContext } from "react";
import ImgSrc from "../../assets/images/ImgSrc.js";
import "../styles/CalqulatorHeader.css";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import baseUrl from "../../config/config";
import { NumericFormat as NumberFormat } from "react-number-format";
import { StateContext } from "../../context/StateContext.js";

function CalqulatorHeader(props) {
  const [homeWorth, setHomeWorth] = useState("");
  const [homeOwed, setHomeOwned] = useState("");
  const { timeFrame, setTimeFrame } = useContext(StateContext);
  const [timeFrameList, setTimeFrameList] = useState([]);
  const [homeWorthErrMsg, setHomeWorthErrMsg] = useState("");
  const [timeFrameErrMsg, setTimeFrameErrMsg] = useState("");
  const {
    isCalqulator,
    isBuilder,
    isBuilderCalqulator,
    setCalculatedResult,
    lenderRealtorType,
    builderType,
    scrollToSection,
  } = useContext(StateContext);

  useEffect(() => {
    fetch(`${baseUrl.baseUrl}get-builders-ppg-timeframes/`, {
      headers: {
        Accept: "application/json",
        Authorization: `${process.env.REACT_APP_API_KEY}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const result = await response.json();
        if (response.status === 200 || response.status === 201) {
          setTimeFrameList(result.result);
          result && result.result.map((res) => {});
        } else if (response.status === 403) {
          localStorage.setItem("access_token", "");
          props.history.push("/");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleCalcultedValue = () => {
    let isValid = true;

    if (homeWorth === null || homeWorth === "") {
      setHomeWorthErrMsg("Please enter the departing residence worth");
      isValid = false;
    } else if (parseInt(homeWorth.replace(/[$,]/g, "")) == 0) {
      setHomeWorthErrMsg("Departing residence worth should be more than 0");
      isValid = false;
    } else {
      setHomeWorthErrMsg("");
    }

    if (
      (isBuilder || isBuilderCalqulator || (builderType && isCalqulator)) &&
      (timeFrame === null || timeFrame === "")
    ) {
      setTimeFrameErrMsg("Please select the time frame");
      isValid = false;
    } else {
      setTimeFrameErrMsg("");
    }

    if (isValid) {
      let homeWorthValue =
        homeWorth === "" ||
        homeWorth === undefined ||
        homeWorth === NaN ||
        homeWorth === null
          ? 0
          : parseInt(homeWorth.replace(/[$,]/g, ""));
      let homeOwedValue =
        homeOwed === "" ||
        homeOwed === undefined ||
        homeOwed === NaN ||
        homeOwed === null
          ? 0
          : parseInt(homeOwed.replace(/[$,]/g, ""));
      let timeFrameValue =
        timeFrame === "" ||
        timeFrame === undefined ||
        timeFrame === NaN ||
        timeFrame === null
          ? 0
          : Number(timeFrame);

      if (
        ((isBuilder || isBuilderCalqulator || (builderType && isCalqulator)) &&
          homeWorthValue > 0 &&
          timeFrameValue) ||
        ((!isBuilder || !isBuilderCalqulator || !builderType) &&
          homeWorthValue > 0)
      ) {
        fetch(
          `${
            isBuilder || isBuilderCalqulator || (builderType && isCalqulator)
              ? `${baseUrl.baseUrl}get-eligibility-calqulator-result?departing_residence_worth=${homeWorthValue}&owed_on_departing_residence=${homeOwedValue}&ppg_time_frame_id=${timeFrameValue}`
              : `${baseUrl.baseUrl}get-eligibility-calqulator-result?departing_residence_worth=${homeWorthValue}&owed_on_departing_residence=${homeOwedValue}`
          }`,
          {
            method: "GET",
            headers: {
              Authorization: `${process.env.REACT_APP_API_KEY}`,
            },
          }
        )
          .then(async (response) => {
            const result = await response.json();
            if (response.status === 201 || response.status === 200) {
              setCalculatedResult(result?.cltv_percentage);
            } else if (response.status === 403) {
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
    scrollToSection();
  };

  return (
    <div className="main-header-container">
      <div className="header-container">
        <img
          className="header-logo"
          src={ImgSrc.calqueLogo}
          alt="calque_logo"
        />
      </div>
      <div className="header-container">
        <h1 className="header-main-heading">Eligibility Calqulator</h1>
      </div>
      {isBuilder || isBuilderCalqulator ? (
        <div className="header-container">
          <h3 className="header-sub-heading mob-sub-heading">
            Find out if your existing home is a good fit for our Build Before
            You Sell program
          </h3>
        </div>
      ) : builderType && isCalqulator ? (
        <div className="header-container">
          <h3 className="header-sub-heading mob-sub-heading">
            Find out if your existing home is a good fit for our Build Before
            You Sell program
          </h3>
        </div>
      ) : isCalqulator ? (
        <div className="header-container">
          <h3 className="header-sub-heading mob-sub-heading">
            Find out if your existing home is a good fit for our Buy Before You
            Sell programs
          </h3>
        </div>
      ) : lenderRealtorType && isCalqulator ? (
        <div className="header-container">
          <h3 className="header-sub-heading mob-sub-heading">
            Find out if your existing home is a good fit for our Buy Before You
            Sell programs
          </h3>
        </div>
      ) : (
        <></>
      )}
      <div className="header-box-container">
        <div className="header-sub-container">
          <p className="form-label">
            How much is the departing residence worth?
          </p>
          <NumberFormat
            className="form-text"
            thousandSeparator={","}
            decimalSeparator={"."}
            autoComplete="off"
            decimalScale={2}
            prefix="$ "
            allowNegative={false}
            inputMode="numeric"
            placeholder="$ 0"
            allowEmptyFormatting
            maxLength={13}
            value={homeWorth}
            onChange={(e) => {
              setHomeWorth(e.target.value);
              if (homeWorth?.trim() !== "") {
                setHomeWorthErrMsg("");
              }
            }}
          />
        </div>
        <div className="header-sub-container">
          <p className="form-label">
            How much is owed on the departing residence (all liens)?
          </p>
          <NumberFormat
            className="form-text"
            thousandSeparator={","}
            decimalSeparator={"."}
            autoComplete="off"
            decimalScale={2}
            prefix="$ "
            allowNegative={false}
            inputMode="numeric"
            placeholder="$ 0"
            allowEmptyFormatting
            maxLength={13}
            value={homeOwed}
            onChange={(e) => {
              setHomeOwned(e.target.value);
            }}
          />
        </div>
        {isBuilder || isBuilderCalqulator ? (
          <div className="header-sub-container">
            <p className="form-label">
              When will the new build home be ready to move in?
            </p>
            <Select
              name="timeFrameValue"
              id="timeFrameValue"
              className="form-text"
              variant="standard"
              sx={{ width: "300px" }}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <p className="form-label" style={{ color: "gray" }}>
                      ---Select Time Frame---
                    </p>
                  );
                }
                const selectedItem = timeFrameList.find(
                  (item) => item.ppg_timeframe_id === selected
                );
                return selectedItem ? selectedItem.ppg_timeframe : "";
              }}
              onChange={(e) => {
                setTimeFrame(e.target.value);
                if (e.target.value !== "") {
                  setTimeFrameErrMsg("");
                }
              }}
            >
              {timeFrameList.map((item, index) => (
                <MenuItem
                  className="options"
                  key={index}
                  value={item.ppg_timeframe_id}
                >
                  {item.ppg_timeframe}
                </MenuItem>
              ))}
            </Select>
          </div>
        ) : builderType && isCalqulator ? (
          <div className="header-sub-container">
            <p className="form-label">
              When will the new build home be ready to move in?
            </p>
            <Select
              name="timeFrameValue"
              id="timeFrameValue"
              className="form-text"
              variant="standard"
              sx={{ width: "300px" }}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <p className="form-label" style={{ color: "gray" }}>
                      ---Select Time Frame---
                    </p>
                  );
                }
                const selectedItem = timeFrameList.find(
                  (item) => item.ppg_timeframe_id === selected
                );
                return selectedItem ? selectedItem.ppg_timeframe : "";
              }}
              onChange={(e) => {
                setTimeFrame(e.target.value);
                if (e.target.value !== "") {
                  setTimeFrameErrMsg("");
                }
              }}
            >
              {timeFrameList.map((item, index) => (
                <MenuItem
                  className="options"
                  key={index}
                  value={item.ppg_timeframe_id}
                >
                  {item.ppg_timeframe}
                </MenuItem>
              ))}
            </Select>
          </div>
        ) : (
          <></>
        )}
        {homeWorthErrMsg ? (
          <span className="form-error">{homeWorthErrMsg}</span>
        ) : timeFrameErrMsg ? (
          <span className="form-error">{timeFrameErrMsg}</span>
        ) : (
          ""
        )}
      </div>
      <Button
        className="main-btn"
        variant="outlined"
        type="submit"
        onClick={handleCalcultedValue}
      >
        Check Fit
      </Button>
    </div>
  );
}

export default CalqulatorHeader;

import React, { createContext, useEffect, useRef, useState } from "react";
import baseUrl from "../config/config";

export const StateContext = createContext();

function StateProvider({ children }) {
  const currentURL = window.location.href;
  const urlObj = new URL(currentURL);
  const pathSegments = urlObj.pathname.split("/");
  const isCalqulator = pathSegments.includes("calqulator");
  const replaceCalUrl = currentURL.replace("/calqulator", "");
  const isBuilder = pathSegments.includes("builder");
  const isBuilderCalqulator = pathSegments.includes("builder-calqulator");
  const replaceBCalUrl = currentURL.replace("/builder-calqulator", "");
  const [timeFrame, setTimeFrame] = useState("");
  const [calculatedResult, setCalculatedResult] = useState(null);
  const [partnerType, setPartnerType] = useState(null);
  const [lenderRealtorType, setLenderRealtorType] = useState(false);
  const [builderType, setBuilderType] = useState(false);

  // Create a ref for the section you want to scroll to
  const sectionRef = useRef(null);

  // Function to scroll to a specific section
  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest" });
    }
  };

  let url = "";
  if (
    pathSegments.includes("lender") ||
    pathSegments.includes("realtor") ||
    pathSegments.includes("builder")
  ) {
    url = `${baseUrl.baseUrlCp}get-partner?name=${pathSegments[2]}`;
  } else {
    url = `${baseUrl.baseUrlCp}get-partner?name=${pathSegments[1]}`;
  }

  useEffect(() => {
    if (pathSegments.includes("build-ahead-mortgage")) {
      setBuilderType(true);
    }

    fetch(url, {
      headers: {
        Authorization: `${process.env.REACT_APP_API_KEY}`,
        "Accept-Encoding": "gzip, deflate",
      },
    })
      .then(async (response) => {
        const result = await response.json();
        if (response.status === 201 || response.status === 200) {
          setPartnerType(result?.calque_partner_type);
          if (
            result?.calque_partner_type === 1 ||
            result?.calque_partner_type === 2
          ) {
            setLenderRealtorType(true);
          } else if (result?.calque_partner_type === 3) {
            setBuilderType(true);
          }
        } else if (response.status === 404) {
          setPartnerType(null);
          setLenderRealtorType(true);
        }
      })
      .catch((error) => {
        console.error("error message", error);
      });
  }, []);

  let data = {
    currentURL,
    replaceCalUrl,
    replaceBCalUrl,
    isCalqulator,
    isBuilder,
    isBuilderCalqulator,
    timeFrame,
    setTimeFrame,
    calculatedResult,
    setCalculatedResult,
    partnerType,
    lenderRealtorType,
    builderType,
    sectionRef, 
    scrollToSection
  };

  return <StateContext.Provider value={data}>{children}</StateContext.Provider>;
}

export default StateProvider;

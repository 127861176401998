import React, { useContext, useEffect } from "react";
import "../styles/CalqulatorBody.css";
import Button from "@mui/material/Button";
import { StateContext } from "../../context/StateContext.js";

function CalqulatorBody() {
  let {
    currentURL,
    isCalqulator,
    replaceCalUrl,
    replaceBCalUrl,
    isBuilder,
    isBuilderCalqulator,
    timeFrame,
    calculatedResult,
    lenderRealtorType,
    builderType,
    sectionRef, 
    scrollToSection
  } = useContext(StateContext);

  useEffect(() => {
    if (calculatedResult !== null) {
      scrollToSection(); // Scroll to the section based on your condition
    }
  }, [calculatedResult]); // You can adjust this condition based on when you want the scroll to happen

  return (
    <>
      {timeFrame !== 6 ? (
        <div className="main-body-container">
          {(isBuilder && isCalqulator && Number(calculatedResult) < 60) ||
          (isBuilderCalqulator && Number(calculatedResult) < 60) ||
          (builderType && isCalqulator && Number(calculatedResult) < 60) ||
          (isCalqulator && !isBuilder && Number(calculatedResult) < 65) ||
          (lenderRealtorType &&
            isCalqulator &&
            Number(calculatedResult) < 65) ? (
            <div ref={sectionRef} className="body-container">
              <p className="message">
                <span className="form-result">Congratulations, </span>this property appears to be a good
                fit for our{" "}
                {isBuilder || isBuilderCalqulator || builderType
                  ? "program"
                  : "programs"}
                ! We can definitely remove your financing contingency and can
                likely unlock equity in your existing home to help buy your next
                dream home.
              </p>
            </div>
          ) : (isBuilder &&
              isCalqulator &&
              Number(calculatedResult) >= 60 &&
              Number(calculatedResult) <= 65) ||
            (isBuilderCalqulator &&
              Number(calculatedResult) >= 60 &&
              Number(calculatedResult) <= 65) ||
            (builderType &&
              isCalqulator &&
              Number(calculatedResult) >= 60 &&
              Number(calculatedResult) <= 65) ||
            (isCalqulator &&
              !isBuilder &&
              Number(calculatedResult) >= 65 &&
              Number(calculatedResult) <= 70) ||
            (lenderRealtorType &&
              isCalqulator &&
              Number(calculatedResult) >= 65 &&
              Number(calculatedResult) <= 70) ? (
            <div ref={sectionRef} className="body-container">
              <p className="message">
                <span className="form-result">Good news, </span>it appears this property may be a fit
                for one or more of our{" "}
                {isBuilder || isBuilderCalqulator || builderType
                  ? "program"
                  : "programs"}{" "}
                based on the estimates you gave us. We can likely remove your
                financing contingency and may be able to unlock equity in your
                existing home to help buy your next dream home.
              </p>
            </div>
          ) : (isBuilder && isCalqulator && Number(calculatedResult) > 65) ||
            (isBuilderCalqulator && Number(calculatedResult) > 65) ||
            (builderType && isCalqulator && Number(calculatedResult) > 65) ||
            (isCalqulator && !isBuilder && Number(calculatedResult) > 70) ||
            (lenderRealtorType &&
              isCalqulator &&
              Number(calculatedResult) > 70) ? (
            <div ref={sectionRef} className="body-container">
              <p className="message">
                <span className="form-result">Sorry, </span>this property does not appear to be a good
                fit for our{" "}
                {isBuilder || isBuilderCalqulator || builderType
                  ? "Build Before You Sell"
                  : "Buy Before You Sell"}{" "}
                {isBuilder || isBuilderCalqulator || builderType
                  ? "program"
                  : "programs"}{" "}
                given the equity position. Please reach out to a Calque
                Representative to discuss. Click{" "}
                <a
                  target="_blank"
                  href="https://calendly.com/d/259-m4z-nts/trade-in-mortgage?month=2024-10"
                  style={{
                    color: "#317aaf",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  here
                </a>{" "}
                to contact us.
              </p>
            </div>
          ) : (
            <></>
          )}
          {(isBuilder && isCalqulator && Number(calculatedResult) < 60) ||
          (isBuilderCalqulator && Number(calculatedResult) < 60) ||
          (builderType && isCalqulator && Number(calculatedResult) < 60) ||
          (isCalqulator && !isBuilder && Number(calculatedResult) < 65) ||
          (lenderRealtorType &&
            isCalqulator &&
            Number(calculatedResult) < 65) ? (
            <div className="body-sub-container">
              <div className="body-container">
                <p className="message sub-message">
                  Please fill out our 5-minute questionnaire so a Calque
                  representative can run more detailed numbers to confirm that
                  your home qualifies.
                </p>
              </div>
              <a
                href={
                  isCalqulator ||
                  isBuilder ||
                  (builderType && isCalqulator) ||
                  (lenderRealtorType && isCalqulator)
                    ? replaceCalUrl
                    : isBuilderCalqulator
                    ? replaceBCalUrl
                    : currentURL
                }
                rel="noopener noreferrer"
                className="footer_link"
              >
                <Button className="main-btn" variant="outlined" type="submit">
                  Get Started
                </Button>
              </a>
            </div>
          ) : (isBuilder &&
              isCalqulator &&
              Number(calculatedResult) >= 60 &&
              Number(calculatedResult) <= 65) ||
            (isBuilderCalqulator &&
              Number(calculatedResult) >= 60 &&
              Number(calculatedResult) <= 65) ||
            (builderType &&
              isCalqulator &&
              Number(calculatedResult) >= 60 &&
              Number(calculatedResult) <= 65) ||
            (isCalqulator &&
              !isBuilder &&
              Number(calculatedResult) >= 65 &&
              Number(calculatedResult) <= 70) ||
            (lenderRealtorType &&
              isCalqulator &&
              Number(calculatedResult) >= 65 &&
              Number(calculatedResult) <= 70) ? (
            <div className="body-sub-container">
              <div className="body-container">
                <p className="message sub-message">
                  Please fill out our 5-minute questionnaire so a Calque
                  representative can run more detailed numbers and confirm that
                  your home qualifies.
                </p>
              </div>
              <a
                href={
                  isCalqulator ||
                  isBuilder ||
                  (builderType && isCalqulator) ||
                  (lenderRealtorType && isCalqulator)
                    ? replaceCalUrl
                    : isBuilderCalqulator
                    ? replaceBCalUrl
                    : currentURL
                }
                rel="noopener noreferrer"
                className="footer_link"
              >
                <Button className="main-btn" variant="outlined" type="submit">
                  Get Started
                </Button>
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="main-body-container">
          <div ref={sectionRef} className="body-container">
            <p className="message">
              <span className="form-result">Sorry, </span>this property does not appear to be a good fit
              for our{" "}
              {isBuilder || isBuilderCalqulator || builderType
                ? "Build Before You Sell"
                : "Buy Before You Sell"}{" "}
              {isBuilder || isBuilderCalqulator || builderType
                ? "program"
                : "programs"}{" "}
              given the equity position. Please reach out to a Calque
              Representative to discuss. Click{" "}
              <a
                target="_blank"
                href="https://calendly.com/d/259-m4z-nts/trade-in-mortgage?month=2024-10"
                style={{
                  color: "#317aaf",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                here
              </a>{" "}
              to contact us.
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default CalqulatorBody;

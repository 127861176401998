import './App.css';
import Dashboard from './components/dashboard/Dashboard.js';
import StateProvider from './context/StateContext.js';

function App() {
  return (
    <div className="App">
      <StateProvider>
        <Dashboard/>
      </StateProvider>
    </div>
  );
}

export default App;